<template>
  <!-- dense view -->
  <div v-if="dense">
    <v-switch v-model="switchState"
              inset hide-details
              :disabled="!device.gatewayOnline || loading"
              :loading="loading"
              class="mt-1 ml-4"
              @change="updateProp"/>
    <div class="mt-3" v-if="switchState"
          v-html="$t('device-list.state.on')"/>
    <div class="mt-3" v-else
          v-html="$t('device-list.state.off')"/>
  </div>

  <!-- normal size view -->
  <div v-else>
    <v-switch v-model="switchState"
              inset
              class="pb-1"
              :disabled="!device.gatewayOnline || loading"
              :loading="loading"
              @change="updateProp"/>
  </div>
</template>

<script>

export default {
  name: 'SwitchInput',

  components: {},

  props: {
    device: Object,
    dense: Boolean
  },

  data: function () {
    return {
      switchState: this.device?.state,
      allowValueChange: true,
      cooldown: 60000,
      changeTimeout: null,
      loading: false
    }
  },

  methods: {
    directGet() {
      this.$rhRequest.sendGet({
            endpoint: `device/default-actor-dco/${this.device.encryptedId}`,
          },
          (r) => {
            if (this.value != r?.data?.data) {
              this.value = r?.data?.data?.mapping?.value
              // eslint-disable-next-line vue/no-mutating-props
              this.device.lastChanged = Math.floor(Date.now() / 1000)
            }
          }
      )
    },

    updateProp() {
      this.loading = true

      let self = this

      this.allowValueChange = false
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = setTimeout(() => {
        self.allowValueChange = true
        this.directGet()
        this.$root.$emit('device-update-finished', this.device)
      }, self.cooldown)

      this.$rhRequest.sendPost({
            endpoint: 'devices/update-property',
            data: {
              deviceId: this.device.id,
              state: this.switchState
            }
          },
          function () {
            self.loading = false
          },
          function (error) {
            console.error(error)
            if (error.code !== 'ECONNABORTED') {
              this.$root.bisatoast.error({message: this.$t('app.generic-error')})
            }
            self.loading = false
          })
      this.$root.$emit('device-update-started', this.device, this.actuator, this.switchState)
    }
  },

  watch: {
    device: function () {
      if (this.allowValueChange) {
        this.switchState = this.device?.state
      } else {
        if (this.value === this.actuator?.value) {
          this.$root.$emit('device-update-finished', this.device)
        }
      }
    }
  }
}

</script>
